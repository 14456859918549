/*========================= section-tickets */

.support-dialog .p-dialog-content {
    overflow: auto !important;
}

.section-tickets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100vh - 120px);
}

.section-tickets .tickets-area {
    height: 100%;
    width: 40%;
    position: relative;
}

.section-tickets .tickets-area .create-ticket {
    height: 50px;
    background-color: #ddd;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 1px;
    width: 100%;
    margin-top: 8px;
    border-radius: 12px;
    cursor: pointer;
    transition: .4s;
    font-size: 18px;
}

.section-tickets .tickets-area .create-ticket:hover {
    background-color: #ccc;
}

.section-tickets .tickets-area .create-ticket i {
    padding-right: 10px;
}

.section-tickets .tickets-inner-area {
    height: calc(100% - 58px);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.section-tickets .chat-area {
    height: 100%;
    width: calc(60% - 8px);
}

.section-tickets .tabs-section {
    margin-bottom: 0px !important;
    padding: 20px 30px !important;
}

.section-tickets .tickets {
    overflow: auto;
    height: calc(100% - 50px);
    margin-top: 50px;
}

.section-tickets .tickets-area .tab-area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-bottom: 2px solid #eee;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.section-tickets .tickets-area .tab-area label {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .4s;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-weight: 500;
    font-size: 15px;
    color: #666;
}

.section-tickets .tickets-area .tab-area label:hover {
    background-color: #f8f8f8;
    color: #222;
}

.section-tickets .tickets-area .tab-area label.active {
    background-color: #eee;
    color: #222;
}

.section-tickets .tickets-area .tab-area label:first-child {
    border-top-left-radius: 12px;
}

.section-tickets .tickets-area .tab-area label:last-child {
    border-top-right-radius: 12px;
}

.section-tickets .tickets .loading {
    height: 100%;
}

.section-tickets .tickets .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px solid #eee;
    padding: 15px;
    transition: .4s;
    cursor: pointer;
}

.section-tickets .tickets .item.active {
    background-color: #eee;
}

.section-tickets .tickets .item:last-child {
    border-bottom: 0px;
}

.section-tickets .tickets .item:not(.active):hover {
    background-color: #f5f5f5;
}

.section-tickets .tickets .item .signal {
    width: 20px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.section-tickets .tickets .item .signal i {
    background-color: var(--global-danger-color);
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    padding-top: 1.5px;
}

.section-tickets .tickets .item .status {
    width: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-tickets .tickets .item .status .status-info {
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 13px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    letter-spacing: 0.3px;
}

.section-tickets .tickets .item .status .status-info.success1 {
    background-color: #2cac02;
}

.section-tickets .tickets .item .status .status-info.success2 {
    background-color: #0b6e00;
}

.section-tickets .tickets .item .status .status-info.waiting1 {
    background-color: #ed7100;
}

.section-tickets .tickets .item .status .status-info.waiting2 {
    background-color: #eda800;
}

.section-tickets .tickets .item .status .status-info.danger1 {
    background-color: #b00000;
}

.section-tickets .tickets .item .status .status-info.danger2 {
    background-color: #e52537;
}

.section-tickets .tickets .item .status .ticket-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    margin-top: 5px;
}

.section-tickets .tickets .item .status .ticket-info1 {
    margin-right: 3px;
    color: #555;
}

.section-tickets .tickets .item .status .ticket-info2 {
    color: #333;
}

.section-tickets .tickets .item .content {
    width: calc(100% - 140px);
    min-height: 50px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.section-tickets .tickets .item .content .subject-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.section-tickets .tickets .item .content .subject-area .subject {
    font-weight: bold;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
}

.section-tickets .tickets .item .content .subject-area .date {
    font-size: 13px;
    font-weight: bold;
    color: #444;
}

.section-tickets .tickets .item .content .message-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.section-tickets .tickets .item .content .message-area .message-user {
    font-size: 13px;
    color: #555;
    margin-right: 5px;
    padding-top: 1px;
}

.section-tickets .tickets .item .content .message-area i {
    margin-right: 5px;
    padding-bottom: 3px;
}

.section-tickets .tickets .item .content .message-area .message {
    max-height: 42px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.section-tickets .tickets .item .content .message-area.with-close-button .message {
    padding-right: 35px;
}

.section-tickets .tickets .item .content .message-area .close-button {
    background-color: transparent;
    color: #777;
    position: absolute;
    right: -12px;
    margin-top: -8px;
    transition: .4s;
}

.section-tickets .tickets .item .content .message-area .close-button:hover {
    color: #888;
}

.section-tickets .tickets .item .content .message-area .close-button-loading {
    width: 30px;
    height: 30px;
    padding: 0px;
    color: #777;
    position: absolute;
    right: 0px;
    margin-top: 32px;
}

.section-tickets .tickets .item .button {
    width: 85px;
    height: 48px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    background-color: #e3e3e3;
    color: #333;
    cursor: pointer;
    transition: .4s;
    padding: 0px 10px;
}

.section-tickets .tickets .item .button:hover {
    background-color: #d3d3d3;
}

.section-tickets .tickets .loading-more div div {
    height: 60px !important;
    width: 60px !important;
}

.section-tickets .tickets .no-tickets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #ccc;
}

.section-tickets .tickets .no-tickets i {
    font-size: 48px;
}

.section-tickets .tickets .no-tickets span {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
}

.p-dialog .current-status {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 2px solid #ddd;
}

.p-dialog .current-status .current-status-title {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 5px;
}

.p-dialog .current-status .current-status-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.p-dialog .current-status .current-status-info .color {
    height: 20px;
    width: 20px;
    border-radius: 25px;
    margin-right: 7px;
}

.p-dialog .current-status .current-status-info .color.success1 {
    background-color: #2cac02;
}

.p-dialog .current-status .current-status-info .color.success2 {
    background-color: #0b6e00;
}

.p-dialog .current-status .current-status-info .color.waiting1 {
    background-color: #ed7100;
}

.p-dialog .current-status .current-status-info .color.waiting2 {
    background-color: #eda800;
}

.p-dialog .current-status .current-status-info .color.danger1 {
    background-color: #b00000;
}

.p-dialog .current-status .current-status-info .color.danger2 {
    background-color: #e52537;
}

.p-dialog .current-status .current-status-info .status {
    font-size: 18px;
}

.p-dialog .new-status .new-status-title {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 5px;
}

.p-dialog .new-status .new-status-items {
    padding-top: 10px;
}

.p-dialog .new-status .new-status-items .new-status-item {
    width: 275px;
    height: 35px;
    border-radius: 12px;
    text-align: center;
    margin-top: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    transition: .4s;
}

.p-dialog .new-status .new-status-items .new-status-item.success1 {
    background-color: #2cac02;
}

.p-dialog .new-status .new-status-items .new-status-item.success2 {
    background-color: #0b6e00;
}

.p-dialog .new-status .new-status-items .new-status-item.waiting1 {
    background-color: #ed7100;
}

.p-dialog .new-status .new-status-items .new-status-item.waiting2 {
    background-color: #eda800;
}

.p-dialog .new-status .new-status-items .new-status-item.danger1 {
    background-color: #b00000;
}

.p-dialog .new-status .new-status-items .new-status-item.danger2 {
    background-color: #e52537;
}

.p-dialog .new-status .new-status-items .new-status-item:hover {
    opacity: .8;
}

.section-tickets .chat-area .chat {
    overflow: auto;
    height: 100%;
}

.section-tickets .chat-area .chat .no-chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #bbb;
}

.section-tickets .chat-area .chat .no-chat i {
    font-size: 60px;
}

.section-tickets .chat-area .chat .no-chat span {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 500;
}

.section-tickets .chat-area .chat .inner-chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.section-tickets .chat-area .chat .inner-chat .messages {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-tickets .chat-area .chat .inner-chat .messages.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.section-tickets .chat-area .chat .inner-chat .messages.loading .inner {
    overflow: hidden;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ececec;
    box-shadow: 0px 0px 10px #00000040;
    z-index: 2;
    padding: 10px 15px;
    position: relative;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 170px;
    z-index: 1;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-id {
    font-size: 13px;
    padding-left: 2px;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state {
    color: #fff;
    padding: 5px 15px;
    margin-top: 5px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.success1 {
    background-color: #2cac02;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.success2 {
    background-color: #0b6e00;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.waiting1 {
    background-color: #ed7100;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.waiting2 {
    background-color: #eda800;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.danger1 {
    background-color: #b00000;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state.danger2 {
    background-color: #e52537;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    width: 100%;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
    font-weight: bold;
    font-size: 14px;
    max-width: calc(100% - 520px);
    max-height: 60px;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button {
    width: 150px;
    border-radius: 5px;
    text-align: center;
    padding: 5px 0px;
    background-color: #b00000;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: .4s;
    z-index: 1;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button:hover {
    opacity: .8;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button.loading {
    height: 30px;
}

.section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button.loading div div {
    width: 25px !important;
    height: 25px !important;
    fill: #fff !important;
}

.section-tickets .chat-area .chat .inner-chat .messages .inner {
    padding: 20px;
    padding-bottom: 0px;
    overflow-y: auto;
    position: relative;
}

.section-tickets .chat-area .chat .inner-chat .messages .inner .loading-more div div {
    height: auto !important;
    width: 75px !important;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .left-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 20px;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .right-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .message {
    width: auto;
    max-width: 60%;
    padding: 15px 20px;
    border-radius: 12px;
    border: 0px;
    font-size: 17px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .message.with-option {
    width: 50%;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .option {
    margin-top: 8px;
    width: 50%;
    padding: 5px;
    border-radius: 12px;
    border: 2px solid;
    font-size: 15px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .left-field .message, .section-tickets .chat-area .chat .inner-chat .messages .item .left-field .option {
    background-color: #ccc;
    border-color: #888;
}

.section-tickets .chat-area .chat .inner-chat .messages .item .right-field .message, .section-tickets .chat-area .chat .inner-chat .messages .item .right-field .option {
    background-color: #f2f2f2;
    border-color: #ddd;
}

.section-tickets .chat-area .chat .inner-chat .input-area {
    padding: 20px;
    width: 100%;
    background-color: #ececec;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px #00000040;
    z-index: 2;
    position: relative;
}

.section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert {
    position: absolute;
    left: 0px;
    top: -37px;
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert i {
    background-color: var(--global-yellow-color);
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 18px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .4s;
}

.section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert i:hover {
    background-color: #e3b62a;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area {
    width: 100%;
    position: relative;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input {
    width: 100%;
    height: 45px;
    border-radius: 12px;
    outline: none;
    border: 2px solid transparent;
    padding: 0px 15px;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input:focus {
    border-color: #ddd;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input.with-button {
    padding-right: 50px;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input.danger {
    border-color: #ffc2c2;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .send-button {
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 28px;
    color: var(--global-yellow-color);
    cursor: pointer;
    transition: .4s;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .send-button:hover {
    opacity: .8;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading {
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0px;
    background-color: #fff;
    border-radius: 12px;
    padding-bottom: 5px;
}

.section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading div div {
    width: 50px !important;
    height: 45px !important;
}

.section-tickets .chat-area .chat .inner-chat .input-area .create-options-button {
    width: 165px;
    background-color: #444;
    color: var(--global-yellow-color);
    height: 45px;
    border: 0px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-tickets .chat-area .chat .inner-chat .input-area .create-options-button:hover {
    background-color: #555;
}

.section-tickets .chat-area .chat .inner-chat .input-area .create-options-button i {
    font-size: 18px;
    margin-right: 5px;
}

.p-dialog .create-options .message {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 2px solid #ddd;
}

.p-dialog .create-options .message .title, .p-dialog .create-options .options .title {
    font-weight: 500;
    padding-bottom: 5px;
}

.p-dialog .create-options .options .title span {
    opacity: .8;
    font-size: 14px;
    letter-spacing: 1px;
}

.p-dialog .create-options .message .input, .p-dialog .create-options .options .input {
    width: 100%;
    border: 0px;
    background-color: #eee;
    padding: 10px;
    border-radius: 5px;
    border: 0px;
    outline: none;
}

.p-dialog .create-options .options .new-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    padding: 15px;
    background-color: #eee;
    border-radius: 5px;
    max-height: 230px;
    overflow: auto;
    border: 2px solid #ddd;
}

.p-dialog .create-options .options .new-options .no-option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 25px 0px;
    color: #777;
    font-size: 14px;
    font-weight: 500;
}

.p-dialog .create-options .options .new-options input {
    border: 0px;
    margin-bottom: 15px;
    padding: 5px 12px;
    border-radius: 5px;
    width: 100%;
    outline: none;
}

.p-dialog .create-options .options .new-options input:last-child {
    margin-bottom: 0px;
}

.p-dialog .create-options .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.p-dialog .create-options .button .send-button {
    width: 200px;
    background-color: #444;
    color: var(--global-yellow-color);
    height: 45px;
    border: 0px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.p-dialog .create-options .button .send-button:hover {
    background-color: #555;
}

.create-ticket-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.create-ticket-section .main-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    padding: 30px 0px;
}

.create-ticket-section .main-form.dialog-content {
    padding: 0px 20px 40px 20px !important;
}

.create-ticket-section .main-form .title {
    text-align: center;
    width: 100%;
}

.create-ticket-section .main-form .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.create-ticket-section .main-form .inputs {
    position: relative;
    width: 100%;
}

.create-ticket-section .main-form .inputs {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.create-ticket-section .main-form .inputs label {
    color: #777;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 1px;
    padding-bottom: 6px;
    width: 100%;
}

.create-ticket-section .main-form .inputs input, .create-ticket-section .main-form .inputs textarea {
    width: 100% !important;
    padding: 12px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
}

.create-ticket-section .main-form .inputs input.danger, .create-ticket-section .main-form .inputs textarea.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.create-ticket-section .main-form button, .create-ticket-section .main-form a:not(.email, .bottom-buton) {
    width: 100%;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.create-ticket-section .main-form a:not(.email, .bottom-buton) {
    width: 50%;
}

.create-ticket-section .main-form button:hover, .create-ticket-section .main-form a:not(.email, .bottom-buton):hover {
    background-color: #484848;
}

.create-ticket-section .main-form button div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.create-ticket-section .main-form.dialog-content button {
    background-color: #555;
}

.create-ticket-section .main-form.dialog-content button:hover {
    background-color: #666;
}

.create-ticket-section .main-form .bottom-buton {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
    transition: .4s;
    letter-spacing: 0.5px;
    color: #4c4c4c;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
}

.create-ticket-section .main-form .bottom-buton:hover {
    opacity: .8;
}

.create-ticket-section .main-form .bottom-buton i {
    margin-right: 10px;
}

.create-ticket-section .main-form span {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    width: 50%;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-tickets .tickets-area .create-ticket {
    background-color: #2a2a2a;
    color: var(--global-yellow-color);
}

body.dark .section-tickets .tickets-area .create-ticket:hover {
    background-color: #3a3a3a;
}

body.dark .section-tickets .tickets-area .tab-area {
    border-color: #333;
}

body.dark .section-tickets .tickets-area .tab-area label {
    color: #aaa;
}

body.dark .section-tickets .tickets-area .tab-area label:hover {
    background-color: #292929;
    color: #ccc;
}

body.dark .section-tickets .tickets-area .tab-area label.active {
    background-color: #333;
    color: #eee;
}

body.dark .section-tickets .tickets, body.dark .section-tickets .tickets .item {
    border-color: #333;
}

body.dark .section-tickets .tickets .item.active {
    background-color: #2e2e2e;
}

body.dark .section-tickets .tickets .item:not(.active):hover {
    background-color: #282828;
}

body.dark .section-tickets .tickets .item .status .ticket-info1 {
    color: #bbb;
}

body.dark .section-tickets .tickets .item .status .ticket-info2 {
    color: #ddd;
}

body.dark .section-tickets .tickets .item .content .message-area .message-user {
    color: #999;
}

body.dark .section-tickets .tickets .item .content .subject-area .date {
    color: #aaa;
}

body.dark .section-tickets .tickets .item .content .message-area .close-button {
    color: #999;
}

body.dark .section-tickets .tickets .item .content .message-area .close-button:hover {
    color: #aaa;
}

body.dark .section-tickets .tickets .item .button {
    background-color: #333;
    color: #eee;
}

body.dark .section-tickets .tickets .item .button:hover {
    background-color: #444;
}

body.dark .section-tickets .tickets .no-tickets {
    color: #444;
}

body.dark .p-dialog .current-status {
    border-color: #555;
}

body.dark .section-tickets .chat-area .chat .no-chat {
    color: #777;
}

body.dark .section-tickets .chat-area .chat .inner-chat .messages .title-area {
    background-color: #2a2a2a;
    box-shadow: 0px 0px 10px #111;
}

body.dark .section-tickets .chat-area .chat .inner-chat .messages .item .left-field .message, body.dark .section-tickets .chat-area .chat .inner-chat .messages .item .left-field .option {
    background-color: #333;
    border-color: #444;
}

body.dark .section-tickets .chat-area .chat .inner-chat .messages .item .right-field .message, body.dark .section-tickets .chat-area .chat .inner-chat .messages .item .right-field .option {
    background-color: #666;
    border-color: #888;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area {
    background-color: #2a2a2a;
    box-shadow: 0px 0px 10px #111;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input {
    background-color: var(--global-dark1-color);
    color: #ddd;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input:focus {
    border-color: #444;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input.danger {
    border-color: #ff71714a;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading {
    background-color: var(--global-dark1-color);
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .create-options-button {
    background-color: #3a3a3a;
}

body.dark .section-tickets .chat-area .chat .inner-chat .input-area .create-options-button:hover {
    background-color: #444;
}

body.dark .p-dialog .create-options .message .title, body.dark .p-dialog .create-options .options .title {
    color: #ddd;
}

body.dark .p-dialog .create-options .message {
    border-color: #555;
}

body.dark .p-dialog .create-options .message .input, body.dark .p-dialog .create-options .options .input {
    background-color: #444;
    color: #eee;
}

body.dark .p-dialog .create-options .options .new-options {
    background-color: #333;
    border-color: #444;
}

body.dark .p-dialog .create-options .options .new-options .no-option {
    color: #666;
}

body.dark .p-dialog .create-options .options .new-options input {
    background-color: #444;
    color: #eee;
}

body.dark .p-dialog .create-options .button .send-button {
    background-color: #444;
}

body.dark .p-dialog .create-options .button .send-button:hover {
    background-color: #555;
}

body.dark .create-ticket-section .main-form .title p {
    color: #888;
}

body.dark .create-ticket-section .main-form .inputs label {
    color: #aaa;
}

body.dark .create-ticket-section .main-form .inputs input, body.dark .create-ticket-section .main-form .inputs textarea {
    background-color: #333;
    color: #ddd;
}

body.dark .create-ticket-section .main-form.dialog-content .inputs input, body.dark .create-ticket-section .main-form.dialog-content .inputs textarea {
    background-color: #414141;
}

body.dark .create-ticket-section .main-form.dialog-content button {
    background-color: #2a2a2a;
}

body.dark .create-ticket-section .main-form.dialog-content button:hover {
    background-color: #262626;
}

/* Webkit tarayıcıları için (örn. Safari ve Chrome) */
body.dark .create-ticket-section .main-form .inputs input::placeholder, body.dark .create-ticket-section .main-form .inputs textarea::placeholder { 
    color: #888; 
    opacity: 1; /* Safari'de opacity varsayılan olarak 0.54'tür, bu nedenle bunu eklemekte fayda var */
}

/* Mozilla Firefox için */
body.dark .create-ticket-section .main-form .inputs input::-moz-placeholder, body.dark .create-ticket-section .main-form .inputs textarea::-moz-placeholder {
    color: #888;
    opacity: 1;
}

/* Microsoft Edge ve Internet Explorer 10+ için */
body.dark .create-ticket-section .main-form .inputs input::-ms-input-placeholder, body.dark .create-ticket-section .main-form .inputs textarea::-ms-input-placeholder {
    color: #888;
}

/* Opera 19+ için */
body.dark .create-ticket-section .main-form .inputs input:-ms-input-placeholder, body.dark .create-ticket-section .main-form .inputs textarea:-ms-input-placeholder {
    color: #888;
}

body.dark .create-ticket-section .main-form button, body.dark .create-ticket-section .main-form a:not(.email, .bottom-buton) {
    color: #ddd;
}

body.dark .create-ticket-section .main-form button:hover, body.dark .create-ticket-section .main-form a:not(.email, .bottom-buton):hover {
    background-color: #414141;
}

body.dark .create-ticket-section .main-form .bottom-buton {
    color: #999;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1600px){
    .section-tickets .tickets-area .tab-area label {
        height: 30px;
        font-size: 10px;
    }
    .section-tickets .tickets {
        overflow: auto;
        height: calc(100% - 30px);
        margin-top: 30px;
    }
    .section-tickets .tickets-inner-area {
        height: calc(100% - 48px);
    }
    .section-tickets .tickets-area .create-ticket {
        height: 40px;
        font-size: 14px;
    }
    .section-tickets .tickets .no-tickets i {
        font-size: 34px;
    }
    .section-tickets .tickets .no-tickets span {
        margin-top: 10px;
        font-size: 14px;
    }
    .section-tickets .tickets .item {
        padding: 5px;
    }
    .section-tickets .tickets .item .signal {
        height: 38px;
    }
    .section-tickets .tickets .item .signal i {
        width: 15px;
        height: 15px;
        font-size: 10px;
        margin-left: 0px;
        padding-top: 0px;
    }
    .section-tickets .tickets .item .status {
        width: 65px;
    }
    .section-tickets .tickets .item .status .status-info {
        height: 38px;
        font-size: 9px;
        border-radius: 8px;
    }
    .section-tickets .tickets .item .status .ticket-info {
        font-size: 7px;
        line-height: 1;
    }
    .section-tickets .tickets .item .content {
        width: calc(100% - 92px);
        padding-left: 7px;
        padding-right: 5px;
    }
    .section-tickets .tickets .item .content .subject-area .subject {
        font-size: 12px;
    }
    .section-tickets .tickets .item .content .subject-area .date {
        font-size: 10px;
    }
    .section-tickets .tickets .item .content .message-area .message-user {
        font-size: 10px;
        margin-right: 4px;
    }
    .section-tickets .tickets .item .content .message-area i {
        margin-right: 4px;
        padding-bottom: 0px;
        font-size: 10px;
        padding-top: 2.5px;
    }
    .section-tickets .tickets .item .content .message-area.with-close-button .message {
        padding-right: 25px;
    }
    .section-tickets .tickets .item .content .message-area .message {
        font-size: 11px;
        max-height: 16px;
        -webkit-line-clamp: 1;
    }
    .section-tickets .tickets .item .content .message-area .close-button {
        right: -3px;
        margin-top: 0px;
        padding: 0px;
    }
    .section-tickets .tickets .item .content .message-area .close-button svg {
        width: 18px;
        height: 18px;
    }
    .section-tickets .tickets .item .content .message-area .close-button-loading {
        width: 20px;
        height: 20px;
        margin-top: 40px;
    }
    .section-tickets .chat-area .chat .no-chat i {
        font-size: 44px;
    }
    .section-tickets .chat-area .chat .no-chat span {
        margin-top: 15px;
        font-size: 14px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area {
        padding: 5px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info {
        width: 135px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-id {
        font-size: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state {
        padding: 3px 12px;
        margin-top: 3px;
        font-size: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
        max-width: calc(100% - 450px);
        max-height: 35px;
        font-size: 12px;
        -webkit-line-clamp: 2;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button {
        font-size: 12px;
        width: 130px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button.loading {
        height: 21px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .inner {
        padding: 15px 10px;
        padding-bottom: 5px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .item .left-field, .section-tickets .chat-area .chat .inner-chat .messages .item .right-field {
        margin-bottom: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .item .message {
        padding: 10px 15px;
        border-radius: 10px;
        font-size: 12px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert {
        top: -29px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert i {
        width: 28px;
        height: 28px;
        font-size: 14px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area {
        padding: 15px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input {
        height: 35px;
        font-size: 12px;
        padding: 0px 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input.with-button {
        padding-right: 40px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .send-button {
        right: 8px;
        top: 6.5px;
        font-size: 22px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading {
        height: 35px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading div div {
        width: 40px !important;
        height: 35px !important;
    }
}

@media screen and (max-width: 1350px) and (min-width: 1000px){
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
        max-width: calc(100% - 350px);
    }
}

@media screen and (max-width: 1000px){
    .section-tickets {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
    }
    .section-tickets .tickets-area {
        height: 400px;
        width: 100%;
    }
    .section-tickets .tickets-area .create-ticket {
        height: 33px;
        margin-top: 7px;
    }
    .section-tickets .chat-area {
        height: 500px;
        width: 100%;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .inner .loading div div {
        width: 70px !important;
        height: 70px !important;
    }
}

@media screen and (max-width: 900px){
    .create-ticket-section .main-form {
        width: 100%;
        padding: 100px;
    }
    .create-ticket-section .main-form .title p{
        font-size: 13px;
    }
}

@media screen and (max-width: 800px){
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
        max-width: calc(100% - 350px);
    }
    .create-ticket-section .main-form {
        padding: 100px 50px;
    }
    .create-ticket-section .main-form a:not(.email, .bottom-buton), .create-ticket-section .main-form span {
        width: 60%;
    }
}

@media screen and (max-width: 600px){
    .create-ticket-section .main-form {
        padding: 100px 50px;
    }
    .create-ticket-section .main-form a:not(.email, .bottom-buton), .create-ticket-section .main-form span {
        width: 75%;
    }
}

@media screen and (max-width: 500px){
    .section-tickets .tickets-inner-area {
        height: calc(100% - 44px);
    }
    .section-tickets .tickets-area .create-ticket {
        height: 30px;
        margin-top: 7px;
        font-size: 10px;
        letter-spacing: 0.5px;
    }
    .section-tickets .tickets-area .tab-area {
        border-width: 1.5px;
    }
    .section-tickets .tickets-area .tab-area label {
        height: 25px;
        font-size: 8px;
    }
    .section-tickets .tickets {
        overflow: auto;
        height: calc(100% - 25px);
        margin-top: 25px;
    }
    .section-tickets .tickets .loading div div {
        width: 50px !important;
        height: 50px !important;
    }
    .section-tickets .tickets .loading-more div div {
        height: 40px !important;
        width: 40px !important;
    }
    .section-tickets .tickets .no-tickets i {
        font-size: 28px;
    }
    .section-tickets .tickets .no-tickets span {
        margin-top: 8px;
        font-size: 10px;
    }
    .section-tickets .tickets .item {
        border-width: 1.5px;
    }
    .section-tickets .tickets .item .signal {
        width: 15px;
        height: 30px;
    }
    .section-tickets .tickets .item .signal i {
        width: 12px;
        height: 12px;
        font-size: 8px;
        margin-left: -1px;
    }
    .section-tickets .tickets .item .status {
        width: 50px;
    }
    .section-tickets .tickets .item .status .status-info {
        height: 30px;
        font-size: 7px;
        border-radius: 5px;
    }
    .section-tickets .tickets .item .status .ticket-info {
        font-size: 5px;
        line-height: 1;
        margin-top: 3px;
    }
    .section-tickets .tickets .item .content {
        width: calc(100% - 70px);
        min-height: auto;
        padding-left: 5px;
        padding-right: 0px;
    }
    .section-tickets .tickets .item .content .subject-area .subject {
        font-size: 9px;
    }
    .section-tickets .tickets .item .content .subject-area .date {
        font-size: 7px;
    }
    .section-tickets .tickets .item .content .message-area .message-user {
        font-size: 7px;
        margin-right: 3px;
        padding-top: 0.5px;
    }
    .section-tickets .tickets .item .content .message-area.with-close-button .message {
        padding-right: 18px;
    }
    .section-tickets .tickets .item .content .message-area .message {
        font-size: 8px;
        max-height: 12px;
    }
    .section-tickets .tickets .item .content .message-area .close-button {
        right: -2px;
        margin-top: 1px;
    }
    .section-tickets .tickets .item .content .message-area .close-button svg {
        width: 14px;
        height: 14px;
    }
    .section-tickets .tickets .item .content .message-area .close-button-loading {
        width: 15px;
        height: 15px;
    }
    .section-tickets .chat-area .chat .no-chat i {
        font-size: 34px;
    }
    .section-tickets .chat-area .chat .no-chat span {
        margin-top: 10px;
        font-size: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .inner {
        padding: 10px 5px;
        padding-bottom: 3px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .inner .loading div div {
        width: 50px !important;
        height: 50px !important;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .inner .loading-more div div {
        width: 50px !important;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info {
        width: 85px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-id {
        font-size: 7px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state {
        padding: 3px 8px;
        font-size: 6px;
        margin-top: 2px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
        max-width: calc(100% - 250px);
        max-height: 40px;
        font-size: 8px;
        -webkit-line-clamp: 3;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button {
        width: 75px;
        font-size: 8px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .state-buttons .state-button.loading {
        height: 16px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .item .left-field, .section-tickets .chat-area .chat .inner-chat .messages .item .right-field {
        margin-bottom: 7px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .item .message {
        padding: 7px 12px;
        border-radius: 7px;
        font-size: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area {
        padding: 8px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert {
        top: -21px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .new-message-alert i {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input {
        height: 25px;
        font-size: 9px;
        padding: 0px 7px;
        border-radius: 7px;
        border-width: 1px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area input.with-button {
        padding-right: 27px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .send-button {
        right: 5px;
        top: 6.5px;
        font-size: 16px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading {
        height: 25px;
        border-radius: 7px;
        padding-bottom: 0px;
        margin-top: 2px;
    }
    .section-tickets .chat-area .chat .inner-chat .input-area .input-inner-area .new-message-loading div div {
        width: 29px !important;
        height: 30px !important;
    }
    .create-ticket-section .main-form {
        padding: 50px;
    }
    .create-ticket-section .main-form .inputs label {
        font-size: 13px;
    }
    .create-ticket-section .main-form .inputs input, .create-ticket-section .main-form .inputs textarea {
        font-size: 13px;
        padding: 8px 10px;
    }
    .create-ticket-section .main-form button, .create-ticket-section .main-form a:not(.email, .bottom-buton) {
        font-size: 14px;
    }
    .create-ticket-section .main-form .bottom-buton {
        font-size: 12px;
    }
    .create-ticket-section .main-form a:not(.email, .bottom-buton), .create-ticket-section .main-form span {
        width: 90%;
    }
    .create-ticket-section .main-form span {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px){
    .create-ticket-section .main-form {
        padding: 40px 25px;
    }
    .create-ticket-section .main-form a:not(.email, .bottom-buton), .create-ticket-section .main-form span {
        width: 100%;
    }
}

@media screen and (max-width: 350px){
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info {
        width: 80px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .ticket-info .ticket-state {
        padding: 3px 5px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .subject-info .subject {
        max-width: calc(100% - 200px);
        font-size: 7px;
    }
    .section-tickets .chat-area .chat .inner-chat .messages .title-area .state-button {
        width: 65px;
        font-size: 7px;
    }
}
