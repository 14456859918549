@import '~boxicons/css/boxicons.min.css';

/*========================= genel renkler | eski yeşil: 1abc7b - eski kırmızı: f13005 | kullanım: var(--global-danger-color) */

:root {
    --global-success-color: #03a66d;
    --global-danger-color: #cf303f;
    --global-success-hover-color: #009360;
    --global-danger-hover-color: #bd2232;
    --global-dark1-color: #212121;
    --global-dark2-color: #1c1c1c;
    --global-yellow-color: #FFBF25;
    --global-yellow-light-theme-text-color: #e7a400;

    --verification-approved-color: #0b8116c7;
    --verification-denied-color: #d52f1cc7;
    --verification-pending-color: #eda800cc;
    --verification-default-color: #9b9b9b30;
}

/*========================= body */

body{
    background-color: #fafafa !important;
    color: #1c1c1c;
    font-family: 'Manrope' !important;
    -webkit-font-smoothing: subpixel-antialiased;
    margin: 0px;
    line-height: 1.5 !important;
    transition: .4s;
}

div#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@keyframes open-animation {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes close-animation {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes close-open-animation {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeInSuccess {
    0% {color: var(--global-success-color);}
    100% {color: #333;}
}

@keyframes fadeInDanger {
    0% {color: var(--global-danger-color);}
    100% {color: #333;}
}

@keyframes fadeInSuccessForDark {
    0% {color: var(--global-success-color);}
    100% {color: #ccc;}
}

@keyframes fadeInDangerForDark {
    0% {color: var(--global-danger-color);}
    100% {color: #ccc;}
}

/*========================= input genel */

input[type=search]::-ms-clear{
    display: none; width : 0; height: 0;
}

input[type=search]::-ms-reveal{
    display: none; width : 0; height: 0;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration{
    display: none;
}

/* Webkit tarayıcılar için (örn. Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox için */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Internet Explorer ve Edge için (eğer hala bu tarayıcıları destekliyorsanız) */
input[type="number"]::-ms-clear {
  display: none;
}

input[type="checkbox"].custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fafafa !important;
    border-radius: 8px !important;
    width: 22px !important;
    height: 22px !important;
    padding: 0px !important;
    position: relative !important;
    transition: .4s !important;
}

input[type="checkbox"].custom-checkbox:checked {
    background-color: #eee !important;
}

input[type="checkbox"].custom-checkbox:checked::after {
    content: '';
    display: block;
    width: 4px;
    height: 11px;
    margin-top: 2px;
    margin-left: 7px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
}

body.dark input[type="checkbox"].custom-checkbox {
    background-color: #444 !important;
}

body.dark input[type="checkbox"].custom-checkbox:checked {
    background-color: #3f3f3f !important;
}

body.dark input[type="checkbox"].custom-checkbox:checked::after {
    border-color: #eee !important;
}

/*========================= div genel */

div, table, table tbody {
    scrollbar-width: auto;
    scrollbar-color: #aaa;
}

div::-webkit-scrollbar, table::-webkit-scrollbar, table tbody::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

div::-webkit-scrollbar-thumb, table::-webkit-scrollbar-thumb, table tbody::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 50px;
}

.MuiModal-root.MuiDialog-root {
    z-index: 999999999;
}

.p-dialog-mask {
    z-index: 9999999999 !important;
}

/*========================= scrollbarhide */

.scrollbarhide::-webkit-scrollbar {
    display: none;
}

.scrollbarhide{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*========================= main */

.main {
    margin-left: 310px;
    padding: 50px;
    padding-top: 70px;
    transition: all 400ms ease;
}

.main:not(.topsection) {
    animation-duration: 1s;
    animation-name: open-animation;
    position: relative;
}

.sidebar-close .main {
    margin-left: 110px;
}

.riseAndFallValueAnimation.success {
    animation: fadeInSuccess 4s ease-in-out;
}

.riseAndFallValueAnimation.danger {
    animation: fadeInDanger 4s ease-in-out;
}

body.dark .riseAndFallValueAnimation.success{
    animation: fadeInSuccessForDark 4s ease-in-out;
}

body.dark .riseAndFallValueAnimation.danger{
    animation: fadeInDangerForDark 4s ease-in-out;
}

/*========================= top section */

.topsection {
    padding: 0px;
    position: absolute;
}

.topsectionarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 400ms ease;
    padding: 20px 50px;
    top: 0px;
    position: fixed;
    width: calc(100% - 310px);
    z-index: 999999;
}

body.scrolled .topsectionarea {
    background-color: #ffffff;
    top: 0px;
    box-shadow: 0px 10px 30px #2121212b;
}

.topsection .topsection-left{
    display: flex;
    align-items: center;
    justify-content: start;
}

.topsection .topsection-left .pagetitle{
    font-size: 24px;
    font-weight: 600;
    margin-left: 5px;
    color: #000;
}

.topsection .topsection-left .sidebar-openclose {
    display: none;
    margin-right: 25px;
}

.topsection .topsection-right {
    display: flex;
    align-items: center;
    margin-left: 15px;
    position: relative;
}

.topsection .topsection-right a {
    text-decoration: none;
}

.topsection .topsection-right i {
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    background-color: #e1e1e1;
    color: #212121;
    border-radius: 50px;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .1s;
    position: relative;
}

.topsection .topsection-right i.active {
    color: #212121;
    background-color: transparent;
    font-size: 22px;
}

.topsection .topsection-right i.login {
    font-size: 16px;
}

.topsection .topsection-right i.register {
    font-size: 14px;
}

.topsection .topsection-right i:not(.active):hover {
    color: #000;
    background-color: #d2d2d2;
}

.topsection .topsection-right i.lasticon{
    margin-right: 0px;
}

.topsection .topsection-right .notifications-count {
    position: absolute;
    top: 24px;
    right: -2px;
    color: #000;
    background-color: #e1e1e1;
    box-shadow: 0px 0px 12px #797979;
    font-size: 8px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: 'Manrope';
}

.topsection .topsection-right .notifications-count.danger {
    background-color: var(--global-danger-color) !important;
    box-shadow: 0px 0px 10px #cf303fa1 !important;
}

.topsection .topsection-right .notifications-count.danger i.exclamation {
    background-color: transparent;
    color: #fff;
    margin: 0px;
    font-size: 10px;
    padding-top: 1px;
}

.topsection .topsection-right .notifications-area {
    width: 450px;
    height: 500px;
    background-color: #fff;
    box-shadow: 0px 0px 15px #00000061;
    position: absolute;
    right: 50px;
    top: 60px;
    z-index: 99;
    border-radius: 12px;
    animation: open-animation .5s;
}

/*========================= footer */

.footer {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 310px;
    padding: 0px 50px;
    padding-bottom: 0px;
    transition: all 400ms ease;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.sidebar-close .footer {
    margin-left: 110px;
}

.footer .top {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    margin: 50px 0px;
}

.footer .bottom-border {
    width: 250px;
    height: 10px;
    border-top: 2px solid #f5f5f5;
}

.footer .bottom {
    padding-top: 25px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.footer .bottom.no-top {
    padding: 50px 0px;
}

.footer .item .title {
    margin-bottom: 5px;
    font-weight: 600;
}

.footer .item .links {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.footer .item .links a {
    text-decoration: none;
    color: inherit;
    transition: .4s;
    font-size: 14px;
}

.footer .item .links a:hover {
    color: var(--global-yellow-color);
}

.footer .item .links.social {
    flex-direction: row;
}

.footer .item .links.social a {
    margin-right: 15px;
    font-size: 18px;
}

.footer .item .links.social a:last-child {
    margin-right: 0px;
}

.footer .item .inner-item {
    margin-bottom: 30px;
}

.footer .item .inner-item:last-child {
    margin-bottom: 0px;
}

.footer .item .inner-item .links a {
    margin-bottom: 10px;
}

.footer .item .inner-item .links a:last-child {
    margin-bottom: 0px;
}

.footer .item .inner-item.contact .links a i {
    margin-right: 8px;
}

/*========================= Toastify */

.Toastify{
    z-index: 99999999999999999999999999999999;
}

/*========================= all sections */

.allsections {
    margin-top: 8px;
    overflow: auto;
}

.allsections .allsections-title {
    padding: 0px 0px 20px 6px;
    font-weight: 600;
    font-size: 18px;
}

.allsections .allsections-title i {
    margin-right: 10px;
}

.allsections-border {
    border-radius: 12px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
}

/*========================= page404 */

.page404 {
    display: flex;
    height: 100vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page404 .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    margin-left: 40px;
    margin-right: 50px;
}

.page404 .image .link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-left: -40px;
}

.page404 .image .link a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    color: #444;
    transition: .4s;
}

.page404 .image .link:hover a {
    color: #666;
}

.page404 .image .link a:first-child {
    text-decoration: none;
    margin-right: 6px;
    padding-top: 1px;
}

.page404 .image1 {
    width: 100%;
}

.page404 .image2 {
    width: 500px;
}

/*========================= country-picker */

.country-picker {
    width: 100%;
}

.country-picker .input {
    background-color: #eee;
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.country-picker .input.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.country-picker .input .flag-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-right: 10px;
}

.country-picker .input .flag-area span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.country-picker .input .flag-area img {
    margin-right: 10px;
    width: 24px !important;
    height: auto !important;
}

.section-country-picker-dialog .p-dialog-content {
    padding-left: 15px;
    padding-right: 15px;
}

.country-picker-dialog {
    height: 500px;
}

.country-picker-dialog .search {
    width: 100%;
    margin-bottom: 20px;
    height: 29px;
    padding-left: 10px;
}

.country-picker-dialog .search i {
    position: absolute;
    margin-top: 8px;
    z-index: 0;
    padding-left: 3px;
}

.country-picker-dialog .search input {
    border: 0px;
    padding-left: 30px;
    padding-right: 12px;
    outline: none;
    background-color: transparent;
    z-index: 1;
    position: relative;
    width: 100%;
    font-size: 18px;
}

.country-picker-dialog .items {
    height: 471px;
    overflow: auto;
    padding: 0px 10px;
}

.country-picker-dialog .items .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #eee;
    font-size: 18px;
    cursor: pointer;
    padding: 8px 5px;
}

.country-picker-dialog .items .item:last-child {
    border: 0px;
}

.country-picker-dialog .items .item img {
    margin-right: 12px;
    width: 24px !important;
    height: auto !important;
}

.country-picker-dialog .items .item span {
    transition: .4s;
}

.country-picker-dialog .items .item:hover span, .country-picker-dialog .items .item span.active {
    color: var(--global-yellow-color);
}

.country-picker-dialog .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
    font-size: 18px;
    opacity: .7;
}

.country-picker-dialog .no-data i {
    font-size: 30px;
    margin-bottom: 10px;
}

.country-picker.country-phone-code-picker {
    width: 85px;
    margin-right: 4px;
}

.country-picker.country-phone-code-picker .input .flag-area {
    padding-right: 0px;
}

.country-picker.country-phone-code-picker .input i {
    font-size: 14px;
}

/*========================= koyu tema düzenlemeleri */

body.dark{
    background-color: var(--global-dark2-color) !important;
    color: #eee;
}

body.dark .topsection .topsection-right input{
    background-color: #333;
    border-color: #333;
    color: #fff;
}

body.dark .allsections-border{
    background-color: var(--global-dark1-color);
    border-color: var(--global-dark1-color);
}

body.dark div{
    scrollbar-color: #444;
}

body.dark div::-webkit-scrollbar-thumb{
    background-color: #444;
}

body.dark.scrolled .topsectionarea {
    background-color: var(--global-dark1-color);
    box-shadow: 0px 0px 15px #ffffff1a;
    border-left: 1px solid #333;
}

body.dark .topsection .topsection-left .pagetitle {
    color: #eee;
}

body.dark .topsection .topsection-right i {
    background-color: #333;
    color: #eee;
}

body.dark .topsection .topsection-right i.active {
    background-color: transparent;
}

body.dark .topsection .topsection-right i:not(.active, .exclamation):hover {
    background-color: #444;
    color: #eee;
}

body.dark .topsection .topsection-right .notifications-area {
    background-color: #292929;
    box-shadow: 0px 0px 5px #ffffff2e;
}

body.dark .topsection .topsection-right .notifications-count {
    color: #ccc;
    background-color: #333;
    box-shadow: 0px 0px 12px #000000;
}

body.dark .footer{
    background-color: var(--global-dark1-color);
    color: #eee;
    border-color: #2b2b2b;
}

body.dark .footer .item .links a {
    color: #ddd;
}

body.dark .footer .item .links a:hover {
    color: var(--global-yellow-color);
}

body.dark .footer .bottom-border {
    border-color: #333;
}

body.dark .page404 .image .link a {
    color: #aaa;
}

body.dark .page404 .image .link:hover a {
    color: #888;
}

body.dark .country-picker .input {
    background-color: #333;
}

body.dark .country-picker-dialog .items .item {
    border-color: #444;
}

body.dark .country-picker-dialog .search input {
    color: #eee;
}

body.dark .MuiPaper-root .MuiToolbar-root .MuiInputBase-root input.MuiInputBase-input {
    color: #aaa;
}

body.dark .MuiPaper-root .MuiToolbar-root .MuiInputBase-root:before {
    border-bottom-color: #444;
}

body.dark .MuiPaper-root .MuiToolbar-root .MuiInputBase-root:after {
    border-bottom-color: #888;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1615px) and (min-width: 1025px){
    .main.dashboard.logged-in {
        margin-bottom: 1185px;
    }
}

@media screen and (max-width: 1200px){
    body .main, body .footer {
        margin-left: 0px !important;
    }
    .allsections .allsections-title {
        padding: 0px 0px 20px 2px;
    }
    .topsectionarea {
        width: 100%;
    }
    .topsection .topsection-left .sidebar-openclose {
        display: flex;
        position: relative;
        margin: 0px;
        margin-right: 20px;
        z-index: 1;
    }
    .topsection .topsection-left .pagetitle {
        margin-left: 0px;
    }
    .topsection .topsection-right {
        margin-right: 0px;
    }
}

@media screen and (max-width: 1000px){
    .footer .top {
        justify-content: space-between;
    }
    .page404 .image {
        width: 180px;
        margin-right: 25px;
    }
    .page404 .image .link {
        margin-top: 0px;
        margin-left: -30px;
    }
    .page404 .image .link a {
        font-size: 12px;
    }
    .page404 .image .link a:first-child {
        padding-top: 2px;
    }
    .page404 .image2 {
        width: 350px;
    }
}

@media screen and (max-width: 850px){
    .main, .main.dashboard {
        padding: 20px;
        padding-top: 60px;
    }
    .main.dashboard {
        padding-top: 67px;
    }
    .topsection .topsection-left .pagetitle {
        font-size: 18px;
    }
    .topsection {
        flex-direction: column;
        align-items: start;
        padding: 0px;
    }
    .topsectionarea {
        padding: 20px;
    }
    .topsection .topsection-right {
        margin-right: 0px;
    }
    .topsection .topsection-right .notifications-area {
        right: 0px;
        top: 50px;
        width: 400px;
        height: 450px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 442px;
        max-height: 442px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 361px;
        max-height: 361px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title label {
        font-size: 14px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i {
        font-size: 14px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-date {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element label {
        font-size: 12px;
    }
    .topsection .topsection-right i {
        width: 30px;
        height: 30px;
        font-size: 12px;
        margin-right: 8px;
    }
    .topsection .topsection-right i.active {
        font-size: 18px;
    }
    .topsection .topsection-right i.login {
        font-size: 12px;
    }
    .topsection .topsection-right i.register {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-count {
        top: 20px;
        font-size: 6px;
        width: 15px;
        height: 15px;
    }
    .topsection .topsection-right .notifications-count.danger i.exclamation {
        font-size: 8px;
    }
    .topsection .topsection-left .sidebar-openclose {
        font-size: 16px;
        width: 25px;
        height: 25px;
        padding-bottom: 1px;
        margin-top: 1px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 750px){
    .footer .top {
        display: block;
    }
    .footer .top .item {
        width: 50%;
        display: inline-block;
        vertical-align: text-top;
        margin-bottom: 50px;
    }
    .footer .top .item:nth-child(3), .footer .top .item:nth-child(4) {
        margin-bottom: 0px;
    }
    .footer .item .inner-item {
        margin-bottom: 20px;
    }
    .footer .item .inner-item:last-child {
        margin-bottom: 0px;
    }
    .page404 .image {
        width: 150px;
        margin-right: 0px;
    }
    .page404 .image .link {
        margin-left: -20px;
    }
    .page404 .image .link a {
        font-size: 10px;
    }
    .page404 .image .link a:first-child {
        padding-top: 1px;
    }
    .page404 .image2 {
        width: 275px;
    }
}

@media screen and (max-width: 500px){
    .main, .main.dashboard {
        padding: 10px;
        padding-top: 45px;
    }
    .main.dashboard {
        padding-top: 52px;
    }
    .allsections .allsections-title {
        font-size: 12px;
        padding: 0px 0px 10px 2px;
    }
    .topsection {
        padding: 0px;
    }
    .topsectionarea {
        padding: 15px 10px;
    }
    .topsection .topsection-left .sidebar-openclose {
        margin-right: 10px;
        font-size: 14px;
        width: 20px;
        height: 20px;
    }
    .topsection .topsection-left .pagetitle {
        font-size: 16px;
    }
    .topsection .topsection-right .notifications-area {
        top: 40px;
        width: 350px;
        height: 400px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 392px;
        max-height: 392px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 325px;
        max-height: 325px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        padding: 10px 15px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title label {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-title div i:last-child {
        margin-left: 7px;
    }
    .topsection .topsection-right i {
        width: 25px;
        height: 25px;
        font-size: 10px;
        margin-right: 6px;
    }
    .topsection .topsection-right i.active {
        font-size: 14px;
    }
    .topsection .topsection-right .notifications-count {
        top: 17px;
        font-size: 4px;
        width: 12px;
        height: 12px;
    }
    .topsection .topsection-right .notifications-count.danger i.exclamation {
        font-size: 6px;
        padding-top: 0px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-date {
        font-size: 9px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
        font-size: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element {
        padding: 10px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element .title-area {
        font-size: 11px;
        margin-bottom: 6px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-element label {
        font-size: 11px;
    }
    .footer .item .title {
        font-size: 12px;
    }
    .footer .item .links a {
        font-size: 10px;
    }
    .footer .item .links.social a {
        margin-right: 10px;
        font-size: 12px;
    }
    .footer .item .inner-item.contact .links a i {
        margin-right: 5px;
    }
    .footer .bottom {
        font-size: 12px;
    }
    .page404 {
        flex-direction: column;
    }
    .page404 .image {
        width: 180px;
        margin: 0px;
    }
    .page404 .image .link {
        margin-left: -20px;
        margin-top: -15px;
    }
    .page404 .image2 {
        width: 215px;
        margin-top: 25px;
    }
    .country-picker .input {
        height: 35px;
        font-size: 12px;
    }
    .country-picker .input .flag-area img {
        margin-right: 8px;
        width: 18px !important;
    }
    .country-picker-dialog .search {
        margin-bottom: 15px;
    }
    .country-picker-dialog .search input {
        font-size: 14px;
    }
    .country-picker-dialog .search i {
        margin-top: 7px;
        font-size: 14px;
    }
    .country-picker-dialog .items .item {
        font-size: 14px;
    }
    .country-picker-dialog .items .item img {
        margin-right: 8px;
        width: 18px !important;
    }
    .country-picker-dialog .no-data {
        font-size: 14px;
    }
    .country-picker-dialog .no-data i {
        font-size: 24px;
    }
    .country-picker.country-phone-code-picker {
        width: 60px;
    }
    .country-picker.country-phone-code-picker .input {
        padding: 0px 5px;
    }
    .country-picker.country-phone-code-picker .input i {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px){
    .main, .main.dashboard {
        padding: 10px;
        padding-top: 35px;
    }
    .main.dashboard {
        padding-top: 42px;
    }
    .topsection {
        padding: 0px;
    }
    .topsectionarea {
        padding: 10px;
    }
    .topsection .topsection-left .sidebar-openclose {
        margin-right: 7px;
        font-size: 10px;
        width: 16px;
        height: 16px;
    }
    .topsection .topsection-left .pagetitle {
        font-size: 12px;
    }
    .topsection .topsection-right i {
        width: 22px;
        height: 22px;
        font-size: 8px;
        margin-right: 4px;
    }
    .topsection .topsection-right i.active {
        font-size: 12px;
    }
    .topsection .topsection-right .notifications-count {
        top: 15px;
        width: 11px;
        height: 11px;
    }
    .topsection .topsection-right .notifications-area {
        top: 35px;
        width: 300px;
        height: 375px;
    }
    .topsection .topsection-right .notifications-area .section-notifications {
        height: 367px;
        max-height: 367px;
    }
    .topsection .topsection-right .notifications-area .section-notifications-elements {
        height: 300px;
        max-height: 300px;
    }
}

@media screen and (max-width: 350px){
    .footer .top .item {
        width: 100%;
        margin-bottom: 25px !important;
    }
    .footer .top .item:last-child {
        margin-bottom: 0px !important;
    }
    .footer .item .inner-item {
        margin-bottom: 25px;
    }
    .footer .item .inner-item:last-child {
        margin-bottom: 5px;
    }
    .footer .bottom {
        width: 100%;
        font-size: 12px;
    }
}

@media screen and (max-width: 325px){
    .topsection .topsection-right .notifications-area {
        width: 225px;
    }
}
